import Link from "next/link";
import { useState } from "react";
import propType from "prop-types";
// import Quantity from "./Quantity";
import { CURRENCY } from "@utils/constant";
import { toCapitalize } from "@utils/toCapitalize";



const ResultProductItemNew = ({
  product,
  className,
  replace,
  remove,
  replaceIndex,
}) => {
  const {
    color,
    thumbs,
    material,
    name,
    slug: slugName,
    price,
    size,
    variations,
  } = product;
  const quantity = product.quantity;
  const slug = `/product/${slugName}`;

  const [optionsOpened, setOptionsOpened] = useState(false);

  const handleDeleteClick = () => {
    setOptionsOpened(false);
    remove();
  };

  return (
    <div className="result-product-item-wrapper">
      {/* product actions */}
      <div
        className={`product-options-container ${optionsOpened ? "open" : ""}`}
      >
        <div
          className={`product-options-button ${optionsOpened ? "open" : ""}`}
          onClick={() => {
            setOptionsOpened(!optionsOpened);
          }}
        >
          {optionsOpened ? (
            <img
              src={"/assets/images/assistant/icons/close-options-icon.svg"}
            />
          ) : (
            <img src={"/assets/images/assistant/icons/options-icon.svg"} />
          )}
        </div>
        <div className={`product-options-tray ${optionsOpened ? "open" : ""}`}>
          <div
            className="product-option"
            onClick={() => {
              replace(
                product.id,
                product.categories?.[0]?.name,
                replaceIndex,
                product.unit
              );
            }}
          >
            Reemplazar
          </div>
          <div className="product-option" onClick={handleDeleteClick}>
            Eliminar
          </div>
        </div>
      </div>

      {/* product image */}
      <div className="product-image-container">
        {variations && variations.length ? (
          variations.map(
            (variation, i) =>
              variation.color.name === color && (
                <Link href={slug} key={i} style={{ width: "100%" }}>
                    <img
                      src={variation.color.thumb}
                      alt={product.name}
                      style={{ width: "100%" }}
                    />
                </Link>
              )
          )
        ) : (
          <Link href={slug} style={{ width: "100%" }}>
              <img src={thumbs[0]} alt={name} style={{ width: "100%" }} />
          </Link>
        )}
      </div>

      <div className="product-info-col">
        <div className="product-name">
          {name}
          {!!(variations && variations.length) && (
            <ul className="tt-add-info">
              <li>
                {`
                                    ${toCapitalize(color)},
                                    ${size.toUpperCase()},
                                    ${toCapitalize(material)}
                                `}
              </li>
            </ul>
          )}
        </div>
        <div className="product-item-type-info">
          {product.presentation}{" "}
          {product.unitsper !== 1 ? `x ${product.unitsper}` : ""}
        </div>
      </div>

      <div className="product-quantity">
        {product.presentation === "Caja" ? (
          <div className="tt-add-info">
            × {quantity.toFixed(2)} M<sup>2</sup>
          </div>
        ) : (
          <div>× {quantity.toFixed(2)}</div>
        )}
      </div>

      <div className="product-total-price">
        {CURRENCY + " " + (price * quantity).toFixed(2)}
      </div>
    </div>
  );
};

ResultProductItemNew.propTypes = {
  product: propType.object.isRequired,
};

export default ResultProductItemNew;
