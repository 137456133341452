import { CURRENCY } from "@utils/constant";
import { useState } from "react"
import { AppConfig } from "src/app-config";


export const ResultProductItem = ({itemTitle, itemUnitPrice, itemThumbnail, itemQty = 1, itemPresentation, itemUnitsper, removeItem}) => {
    
    const calculateTotalAreaPrice = () => {
        // return (((itemAreaSize.width * itemAreaSize.height) * (itemAreaSize?.unit === 'cm' ? 0.01 : 1)) / totalAreaCoverage) * itemUnitPrice
        return itemQty * itemUnitPrice
    }
    const [optionsOpened, setOptionsOpened] = useState(false)
    
    return (
        <div className="result-product-item-wrapper">
            <div className={`product-options-container ${optionsOpened ? 'open' : ''}`}>
                <div className={`product-options-button ${optionsOpened ? 'open' : ''}`} onClick={()=>{setOptionsOpened(!optionsOpened)}}>
                    {optionsOpened ? <img src={'/assets/images/assistant/icons/close-options-icon.svg'}/> : <img src={'/assets/images/assistant/icons/options-icon.svg'}/>}
                </div>
                <div className={`product-options-tray ${optionsOpened ? 'open' : ''}`}>
                    <div className="product-option" onClick={()=>{removeItem()}}>replace</div>
                    <div className="product-option" onClick={()=>{setOptionsOpened(false)}}>delete</div>
                </div>
            </div>
            <div className="product-image-container">
                <img src={`${AppConfig.api_url}${itemThumbnail}`} style={{width: '100%'}}/>
            </div>
            <div className="product-info-col">
                <div className="product-name">
                    {itemTitle || 'Porcelanato Default'}
                </div>
                <div className="product-item-type-info">
                    { itemPresentation === 'Caja' ? `${itemPresentation} ${itemUnitsper} m2` : `USD ${itemUnitPrice || 1234 }`}
                </div>
            </div>
            <div className="product-quantity">
                × { itemPresentation === 'Caja' ? `${itemUnitsper * itemQty} m2` : itemQty }
            </div>
            <div className="product-total-price">{CURRENCY} {itemPresentation === 'Caja' ? calculateTotalAreaPrice() : (itemUnitPrice * itemQty)}</div>
        </div>
    )
}