import React from 'react';

const CheckoutProgressbar = ({ progressValue, customNodeCount }) => {
    const nodeCount = customNodeCount || ['Carrito', 'Entrega', 'Pago'];

    const calcNodePosition = (nodeIndex) => {
        let pos = nodeIndex / (nodeCount.length - 1);
        return { left: `${pos * 100}%` };
    };

    const calcBarFill = () => {
        return {
            background: `linear-gradient(90deg, rgb(43, 223, 154) 0%, rgb(43, 223, 154) ${(progressValue / (nodeCount.length - 1)) * 100}%, #bdbdbd ${(progressValue / (nodeCount.length - 1)) * 100}%, #bdbdbd 100%)`
        };
    };

    return (
        <div style={calcBarFill()} className="bar-body">
            {nodeCount.map((node, index) => {
                return (
                    <div
                        key={index}
                        style={calcNodePosition(index)}
                        className={`bar-node-wrapper ${index <= progressValue ? 'active-node' : ''}`}
                    >
                        <div className="bar-node">
                            <div className="bar-node-text">{node}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CheckoutProgressbar;
